import React, { Component } from 'react';
import ItemsService from '../../Services/ItemsService';
import ChannelService from '../../Services/ChannelsService';
import PackageService from '../../Services/PackageService';

import {chunk} from 'underscore';

import {
  Box
} from "react-bulma-components/full";

import {
  faBoxes,
  faDatabase,
  faLink,
  faBinoculars,
  faSearch,
  faSpinner,
  faBoxOpen,
  faWindowClose,
  faCamera,
  faEraser, faCrosshairs, faStickyNote, faFileExcel, faPlusCircle, faEdit, faPlus, faCheck, faPencilAlt, faRecycle, faTrash
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import { 
  faCalendarCheck, 
  faClock, 
  faCalendar, 
  faCheckCircle, 
  faCircle, 
  faStickyNote as faStickyNoteO
} from '@fortawesome/free-regular-svg-icons';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Datatable from '../General/Datatable/Datatable';
import DetailsPC9 from './components/Details/DetailsPC9';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ItemStatusInfo from './components/ItemStatusInfo';
import Loader from '../General/Loader/Loader';
import {ModalCard} from '../General/Modal';

import OnSearch2 from './components/OnSearch';
import SendToDrop from './components/SendToDrop';
import UploadCSV from './components/UploadCSV';
import UploadEmptyRows from './components/UploadEmptyRows';
import Packages from './components/Packages';
import Links from './components/Links';
import OnProductGuide from './components/OnProductGuide';
import AddToQuotation from '../Quotations/components/AddToQuotation';
import AddToPackage from './components/AddToPackage';
import MoveBetweenShootings from './components/MoveBetweenShootings'
import Shootings from '../Shootings/Shooting'
import MoveToSeason from './components/MoveToSeason';
import AddNewPC9 from './components/AddNewPC9'

import DeleteAdaptations from './components/DeleteAdaptations'
import AddChannelToPC9 from './components/AddChannelToPC9';
import SetMultipleField from './components/SetMultipleField'

import { STATUS as ITEM_STATUS} from '../General/Constants/Item';
import { BATCH, ITEMS } from '../General/Language/es';
import Quotation from '../Quotations/Quotations';
import GenerateChannelLink from './components/GenerateChannelLink2'
import AddToShootingV2 from '../Shootings/components/components/AddToShootingV2'
import SetETA from './components/setETA'

import {sortBy} from '../General/Datatable/utils';

class Items extends Component {
  constructor(props) {
    super(props);

    this.enabledButtons = [
      ITEM_STATUS.REJECTED,
      ITEM_STATUS.REQUIRED,
      ITEM_STATUS.UNAVAILABLE
    ];

    this.state = {
      showDetails:false,
      pkg_active:false,
      pkg:null,
      hasChange: false,
      checkList: [],
      data: [],
      loading: true,
      table: {},
      modal: {
        show: false,
        size: ''
      },
      pageLoading: false,
      pincking: false,
      pGuideWait: false,
      renderRows: [],
      queryString: {
        rf: [
          '_id', 
          'pc9', 
          'brand', 
          'gender', 
          'category', 
          'clothes_collection', 
          'status', 
          'season',  
          'swatch',
          'created_at',
          'eta_photos'
        ],
        qf: {
          status: { $exists: true }
        }
      },
      loggedInUser: this.props.loggedInUser,
      filterFills: {}
    };

    this.itemService = new ItemsService();
    this.channelService = new ChannelService();
    this.packageService = new PackageService();
  }

  constructTableWithUserInfo = (table, user) => {
    let newTable = {};
    switch(user.code) {
      case "brand_manager":
        newTable = table;
        break;
      case "marketing":
      case "commercial":
        newTable.checkCounter = true;
        newTable.columns = [
          {
            field: '_id',
            type: 'checklist'
          }, {
            field: 'pc9',
            label: 'PC9',
            onClick: (event, item) => this.showDetailsPC9(item._id),
            sortable: true,
            type: 'link'
          }, {
            field: 'brand',
            label: 'Marca',
            sortable: true
          }, {
            field: 'gender',
            label: 'Género',
            sortable: true
          }, {
            field: 'category',
            label: 'Categoría',
            sortable: true
          }, {
            type: 'icon',
            field:'status.mkt',
            label: 'Copy',
            sortable: true,
            icon: (item) => {
              let icons = {
                required: faCircle,
                pending: faBinoculars,
                available: faCrosshairs,
                assigned: faCalendarCheck,
                toShoot: faClock,
                waitingPhotos: faClock,
                toReview:faPencilAlt,
                approved: faCheckCircle,
                urgent:faBinoculars
              };
  
              return icons[item.status.mkt] ? icons[item.status.mkt] : null;
            },
            color: (item) => {
              let colors = {
                required: 'danger',
                pending: 'grey-light',
                available: 'grey-light',
                assigned: 'grey-light',
                toShoot: 'grey-light',
                waitingPhotos: 'grey-light',
                toReview:'warning',
                approved: 'success',
                urgent:'danger'
              };
  
              return colors[item.status.mkt] ? colors[item.status.mkt] : '';
            },
            dictionary: ITEMS.STATUSCOPY
          },
          {
            field: 'eta_photos.eta',
            type: 'ETA',
            label: 'Fotos',
            extraData: (item) => item.status.current,
            onSearch: (item)=>{
              const itemXpress = new ItemsService();
              if (item.status.current === 'required') {
                itemXpress.put(item._id, {
                  co: [{
                      'status.current': 'pending'
                  }]
                })
                return true;
  
              }
              return false;
            },
            sortable: true
          },
        ];
        newTable.filtersConfig = table.filtersConfig;
        newTable.actions = [{
          title: 'Acciones',
          label: 'Acciones' ,
          disabled: () => this.state.pGuideWait,
          className: 'is-info',
          options: [
            {
              icon: faPlus,
              disabled: false,
              label: 'Alta nuevo PC9',
              className:'is-info',
              onClick: this.newPC9modal
            },
            {
              icon: faBoxOpen,
              label: 'Agregar a paquete',
              onClick: () => this.addToPackage()
            },
            {
              icon: faPlusCircle,
              label: 'Agregar canal',
              onClick: () => this.addChannelToSelectedPC9()
            }
          ]
        }, {
          title:'Paquetes',
          label:'Paquetes',
          icon: faBoxes,
          className: 'is-warning',
          onClick: () => this.openPackageModal()
        }, {
          title:'Links generados',
          label:"Links",
          icon:faLink,
          className: 'is-success',
          onClick: () => this.openLinksModal()
        }];
        newTable.searchFields = ['pc9', 'barcode', 'skus'];
        newTable.emptyLegend = <UploadEmptyRows />;
        newTable.loadingLegend = <Loader />;
        break;
      case "warehouse_picker":
      case "photo_team":
          newTable.checkCounter = false;
          newTable.columns = [
            {
              field: 'pc9',
              label: 'PC9',
              sortable: true,
            }, {
              field: 'brand',
              label: 'Marca',
              sortable: true
            }, {
              field: 'gender',
              label: 'Género',
              sortable: true
            }, {
              field: 'category',
              label: 'Categoría',
              sortable: true
            }, {
              field: 'name',
              label: 'Nombre',
              sortable: true
            }, {
              field: 'status.current',
              label: 'Estatus',
              type: 'status',
              sortable: true,
              dictionary: ITEMS.STATUS
            }, {
              field: 'status.mkt',
              label: 'Copy',
              type: 'status',
              sortable: true,
              dictionary: ITEMS.STATUSCOPY
            }
          ];
          newTable.filtersConfig = table.filtersConfig;
          newTable.actions = [];
          newTable.searchFields = ['pc9', 'barcode', 'skus'];
          newTable.emptyLegend = <UploadEmptyRows />;
          newTable.loadingLegend = <Loader />;
        break;

        case "latam":
          newTable.checkCounter = true;
          newTable.columns = [
            {
              field: '_id',
              type: 'checklist'
            }, {
              field: 'pc9',
              label: 'PC9',
              onClick: (event, item) => this.showDetailsPC9(item._id),
              sortable: true,
              type: 'link'
            }, {
              field: 'brand',
              label: 'Marca',
              sortable: true
            }, {
              field: 'gender',
              label: 'Género',
              sortable: true
            }, {
              field: 'category',
              label: 'Categoría',
              sortable: true
            }, {
              dictionary: ITEMS.STATUS,
              sortable: true,
              field: 'status.current',
              label: 'eComm',
              color: (item) => {
                let colors = {
                  required: 'danger',
                  pending: 'grey-light',
                  available: 'primary',
                  assigned: 'grey-light',
                  toShoot: 'grey-light',
                  waitingPhotos: 'grey-light',
                  toReview:'primary',
                  approved: 'success',
                };

                return colors[item.status.current] ? colors[item.status.current] : '';
              },
              icon: (item) => {
                let icons = {
                  required: faCircle,
                  pending: faBinoculars,
                  available: faCalendar,
                  assigned: faCalendarCheck,
                  toShoot: faClock,
                  waitingPhotos: faClock,
                  toReview:faSearch,
                  approved: faCheckCircle,
                };

                return icons[item.status.current] ? icons[item.status.current] : null;
              },
              title: (item) => item.status.current,
              type: 'icon'
            }, {
              field: 'last_update.date',
              label: 'Actualizado',
              format: ['MOMENT::DD/MM/YYYY hh:mm'],
              sortable: true
            }
          ];
          newTable.filtersConfig = table.filtersConfig;
          newTable.actions = [
            {
              title: 'Agregar a',
              label: 'Agregar a' ,
              disabled: () => this.state.pGuideWait,
              className: 'is-info',
              options: [
                {
                  icon: faBoxOpen,
                  label: 'Cotización',
                  onClick: () => this.addToQuotation()
                }
              ]
            }, {
              title: 'Mis cotizaciones',
              label: <span className="is-hidden-touch">Cotizaciones</span>,
              icon: faBoxes,
              className: 'is-primary',
              onClick: () => this.openQuotations()
            }, {
              title: 'Mis links',
              label: <span className="is-hidden-touch">Links</span>,
              icon: faLink,
              className: 'is-success',
              onClick: () => this.openLinksModal()
            }
          ];
          newTable.searchFields = ['pc9', 'barcode', 'skus'];
          newTable.emptyLegend = <UploadEmptyRows />;
          newTable.loadingLegend = <Loader />;
        break;
      default:
        break;
    }

    this.setState({
      table: newTable
    });
  }

  componentWillMount() {
    let completeTable = {
      checkCounter: true,
      columns: [
        {
          field: '_id',
          type: 'checklist'
        }, {
          field: 'pc9',
          label: 'PC9',
          onClick: (event, item) => this.showDetailsPC9(item._id),
          sortable: true,
          type: 'link'
        }, {
          field: 'brand',
          label: 'Marca',
          sortable: true
        }, {
          field: 'gender',
          label: 'Género',
          sortable: true
        }, {
          field: 'category',
          label: 'Categoría',
          sortable: true
        }, {
          dictionary: ITEMS.STATUS,
          sortable: true,
          field: 'status.current',
          label: 'Fotos',
          color: (item) => {
            let colors = {
              required: 'danger',
              pending: 'grey-light',
              available: 'grey-light',
              assigned: 'grey-light',
              toShoot: 'grey-light',
              waitingPhotos: 'grey-light',
              toReview:'primary',
              approved: 'success',
              urgent:'danger'
            };

            return colors[item.status.current] ? colors[item.status.current] : '';
          },
          icon: (item) => {
            let icons = {
              required: faCircle,
              pending: faBinoculars,
              available: faCrosshairs,
              assigned: faCalendarCheck,
              toShoot: faClock,
              waitingPhotos: faClock,
              toReview:faSearch,
              approved: faCheckCircle,
              urgent:faBinoculars
            };

            return icons[item.status.current] ? icons[item.status.current] : null;
          },
          title: (item) => item.status.current,
          type: 'icon'
        }, {
          field: 'status.mkt',
          sortable: true,
          label: 'Copy',
          dictionary: ITEMS.STATUSCOPY,
          color: (item) => {
            let colors = {
              toReview:'grey-light',
              approved: 'success',
            };

            return colors[item.status.mkt] ? colors[item.status.mkt] : '';
          },
          icon: (item) => {
            let icons = {
              toReview:faSearch,
              approved: faCheckCircle,
            };

            return icons[item.status.mkt] ? icons[item.status.mkt] : null;
          },
          title: (item) => item.status.mkt,
          type: 'icon'
        }, {
          field: 'swatch.url',
          label: 'Swatch',
          sortable: (order, a, b) => {
            if (order === 'asc') {
              if (a !== null && b === null) return 1;
              if (a === null && b !== null) return -1;
            } else {
              if (a === null && b !== null) return 1;
              if (a !== null && b === null) return -1;
            }

            return 0;
          },
          type: 'icon',
          icon: (item) => item.swatch && item.swatch.url ? faStickyNote: faStickyNoteO,
          color: (item) => item.swatch && item.swatch.url ? 'success': 'grey-light'
        }, {
          field: 'eta_photos.eta',
          type: 'ETA',
          label: 'Fecha entrega',
          extraData: (item)=>{
            return item.status.current
          },
          onSearch:(item)=>{
            const itemXpress = new ItemsService();
            if(item.status.current === 'required'){
              itemXpress.put(item._id, {
                co: [{
                    'status.current': 'pending'
                }]
            })
            return true

            }
            return false
          },
          //format: ['MOMENT::DD/MM/YYYY'],
          sortable: true
        }
      ],
      filtersConfig: {
        pc9: {
          type: 'textarea',
          label: 'Varios PC9'
        },
        season: {
          label: 'Temporada',
          conditional: (values, compare) => {
            for (let i in values) {
              if (values[i] === compare) {
                return true;
              }
            }

            return false;
          }
        },
        brand: {
          label: 'Marca',
        },
        gender: {
          label: 'Género',
        },
        category: {
          label: 'Categoría',
        },
        'status.current': {
          label: 'Estatus fotos',
          dictionary: ITEMS.STATUS
        },
        'status.mkt': {
          label: 'Estatus copy',
          dictionary: ITEMS.STATUSCOPY
        },
        clothes_collection:{
          label: 'Colección'
        }
      },
      actions: [
         {
          title: 'Acciones',
          label: 'Acciones' ,
          disabled: () => this.state.pGuideWait,
          className: 'is-info',
          options: [
            {
              icon: faPlus,
              disabled: false,
              label: 'Alta nuevo PC9',
              className:'is-info',
              onClick: this.newPC9modal
            },
            {
              icon: faSearch,
              disabled: false,
              label: 'Solicitar búsqueda',
              className:'is-info',
              onClick: this.searchItems
            },
            {
              icon: faCheck,
              disabled: false,
              label: 'Aprobar copy',
              className: 'is-info',
              onClick: this.approveCopy
            },
            {
              icon: faCamera,
              disabled: this.state.pincking,
              label:'Agregar a shooting',
              className: 'is-info',
              onClick: this.openAddToShooting
            },
            {
              icon: faClock,
              disabled: false,
              label: 'Definir ETA',
              className:'is-info',
              onClick: this.setETA
            },
             {
              icon: faBoxOpen,
              label: 'Agregar a paquete',
              onClick: this.addToPackage
            }, {
              icon: faPlusCircle,
              label: 'Agregar canal',
              onClick: this.addChannelToSelectedPC9
            }, {
              icon: faEraser,
              label: 'Eliminar adapt.',
              onClick: this.deleteAdaptationsFromChannel
            }, {
              icon: faEdit,
              label: 'Editar campo',
              onClick: this.setMultipleField
            }, {
              icon: faDatabase,
              label: 'Cargar Excel',
              onClick: this.showUploadCSV
            }, {
              icon: faRecycle,
              label: 'Mover a DROP',
              onClick: this.sendToDrop
            }
          ]
        },
        {
          title: 'Paquetes',
          label: '',
          icon: faBoxes,
          className: 'is-warning',
          onClick: () => this.openPackageModal()
        }, {
          title:'Links',
          label:'',
          icon:faLink,
          className: 'is-success',
          onClick: () => this.openLinksModal()
        }, 
      ],
      searchFields: ['pc9', 'barcode', 'skus'],
      emptyLegend: <UploadEmptyRows />,
      loadingLegend: <Loader />
    }
    this.constructTableWithUserInfo(completeTable, this.props.loggedInUser);
  }

  approveCopy = () => {
    if (!this.state.checkList) return;

    this.itemService.approveCopy(this.state.checkList)
    .then(() => {
      toast.success("Se ha actualizado el estatus del copy");
      this.setState({
        checkList: []
      });
      this.fetch();
    });
  }

  closeModal = () => {
    let modal = {
      show: false
    };

    this.setState({
      modal: modal,
      hasChange: false
    });
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    let qs = {
      rf: [
        '_id', 
        'pc9', 
        'brand', 
        'gender', 
        'category', 
        'clothes_collection', 
        'status',  
        'swatch', 
        'season', 
        'eta_photos',
        'updated_at', 
        'last_update'
      ],
      qf: {
        'status.current': { $in: [
          ITEM_STATUS.PENDING,
          ITEM_STATUS.AVAILABLE,
          ITEM_STATUS.ASSIGNED,
          ITEM_STATUS.TOSHOOT,
          ITEM_STATUS.WAITINGPHOTOS,
          ITEM_STATUS.TOREVIEW,
          ITEM_STATUS.APPROVED,
          ITEM_STATUS.URGENT,
          ITEM_STATUS.REJECTED,
          ITEM_STATUS.UNAVAILABLE,

        ] }
      }
    };
    
    if (this.state.pkg !== null) {
      qs.qf.package = this.state.pkg._id;
    }

    this.itemService.fieldsUnique(Object.keys(this.state.table.filtersConfig))
      .then((response) => {
        let assign = {};
        if (response && response.d && response.d[0]) {
          assign = response.d[0];
        }
        this.setState({
          filterFills: assign
        })
      });
    this.itemService.get(qs)
      .then((response) => {
        let orderedData = sortBy(response.d,{
          field: 'updated_at',
          order: 'desc'
        })
        this.setState({
          data: orderedData,
          loading: false
        });
      });
  }

  fetchPKG = async () => {
    let queryString = {
      rf: [
        '_id',
        'pc9',
        'brand',
        'gender',
        'category',
        'clothes_collection',
        'status',
        'month',
        'skus',
        'swatch',
        'season',
        'updated_at',
        'eta_photos'
      ],
      qf: {
        package:this.state.pkg._id
      }
    }

    await this.itemService.get(queryString)
      .then((response) => {
        let orderedData = sortBy(response.d,{
          field: 'brand',
          order: 'asc'
        })
        this.setState({
          data: orderedData,
          loading: false
        });

        this.itemService.fieldsUnique(Object.keys(this.state.table.filtersConfig), {
          _id: response.d.map((i) => i._id)
        })
        .then((response) => {
          let assign = {};
          if (response && response.d && response.d[0]) {
            assign = response.d[0];
          }
          this.setState({
            filterFills: assign
          })
        });
      });
    
  }


  openModal = () => {
    let modal = this.state.modal;
    modal.show = true;
    this.setState({ modal: modal });
  }


  onChangeUploadCSV = (state) => {
    this.openModal();
  }


  onChangeDatatable = (state) => {
    this.setState({
      checkList: state.checkList,
      renderRows:state.renderRows
    });
  }

  openMoveToSeason = () => {
    let { modal } = this.state;
    modal.title = 'Mover de temporada';
    modal.size = 'is-medium';
    modal.body = (
      <MoveToSeason
        checkList={this.state.checkList}
        onMove={() => {
          this.closeModal();
          this.fetch();
        }}
      />
    );
    modal.show = true;
    this.setState({
      modal
    });
  }

  openShootingsModal = () => {
    let modal = this.state.modal;
    modal.title = 'Shootings';
    modal.size = 'is-large';
    modal.body = (
      <Shootings
        openPackage={this.openPackage}
        closeModal={this.closeModal}
        checkList={this.state.checkList}
        loggedInUser={this.state.loggedInUser}
        season={process.env.APP_DEFAULT_SEASON}
      />
    )

    this.setState({
      modal: modal
    }, this.openModal());

  }


  openPackageModal = () => {
    let modal = this.state.modal;
    modal.title = 'Mis paquetes y compartidos conmigo';
    modal.size = 'is-large';
    modal.body = (
      <Packages
        openPackage={this.openPackage}
        closeModal={this.closeModal}
        checkList={this.state.checkList}
        loggedInUser={this.state.loggedInUser}
      />
    )

    this.setState({
      modal: modal
    }, this.openModal());

  }


  addToQuotation = () => {
    let modal = this.state.modal
    modal.title = 'Agregar a cotización'
    modal.size = 'is-medium'
    modal.body = (
      <AddToQuotation
      checkList={this.state.checkList}
      close={this.closeModal}
      loggedInUser={this.state.loggedInUser}

      />
    );

    this.setState({
      modal
    }, this.openModal());
  }

  moveBetweenShootings = () => {
    let modal = this.state.modal
    modal.title = 'Mover a un shooting'
    modal.size = 'is-medium'
    modal.body = (
      <MoveBetweenShootings
      checkList={this.state.checkList}
      close={this.closeModal}
      seasonId={process.env.APP_DEFAULT_SEASON}
      />
    );

    this.setState({
      modal
    }, this.openModal())
  }

  deleteAdaptationsFromChannel = () => {
    let modal = this.state.modal
    modal.title= 'Eliminar adaptaciones'
    modal.size = 'is-small'

    modal.body = (
      <DeleteAdaptations 
      close={this.closeModal}
      seasonId={process.env.APP_DEFAULT_SEASON}
      checkList={this.state.checkList}
      />
    )

    this.setState({
      modal
    }, this.openModal())
  }

  addChannelToSelectedPC9 = () => {
    let modal = this.state.modal
    modal.title= 'Agregar canal'
    modal.size = 'is-small'

    modal.body = (
      <AddChannelToPC9 
      close={this.closeModal}
      seasonId={process.env.APP_DEFAULT_SEASON}
      checkList={this.state.checkList}
      />
    )

    this.setState({
      modal
    }, this.openModal())
  }

  setMultipleField = () => {
    let modal = this.state.modal
    modal.title= 'Editar campo'
    modal.size = 'is-small'

    modal.body = (
      <SetMultipleField 
      close={this.closeModal}
      fetch={this.fetch}
      seasonId={process.env.APP_DEFAULT_SEASON}
      checkList={this.state.checkList}
      />
    )

    this.setState({
      modal
    }, this.openModal())
  }

  openLinksModal = () => {
    let modal = this.state.modal;
    modal.title ="Mis links de descarga";
    modal.size = 'is-large';
    modal.body = (
      <Links
        closeModal={this.closeModal}
        checkList={this.state.checkList}
        loggedInUser={this.state.loggedInUser}
        seasonId={process.env.APP_DEFAULT_SEASON}
      />
    );

    this.setState({
      modal: modal
    }, this.openModal());
  };

  showDetailsPC9 = (itemId) => {
    this.itemService.getItemById(itemId)
      .then((item) => {
        this.setState({
          showDetails:true,
          clickedItem:item,
        })
      });
  }

  showUploadCSV = () => {
    let modal = this.state.modal;
    modal.title = "Cargar o actualizar PC9's por lote";
    modal.size = 'is-medium';
    modal.body = (
      <UploadCSV
        onChange={this.onChangeUploadCSV}
        season={process.env.APP_DEFAULT_SEASON}
        close={this.closeModal}
        updateTable={this.fetch}
      />
    );

    this.setState({
      modal: modal
    }, this.openModal());
  }

  showProductGuide = async () => {
    await this.setState({
      pGuideWait: true
    });

    let modal = this.state.modal;
    let itemList = [];
    if (this.state.checkList.length) {
      let arrays = chunk(this.state.checkList, 40);

      for (let i in arrays) {
        let itemsQuery = await this.itemService.get({
          rf: ['_id'],
          qf: {
            _id: arrays[i],
            "status.product_guide": "required"
          }
        });
        let items = [];

        if (itemsQuery.d) {
          items = itemsQuery.d;
        }

        for (let i in items) {
          itemList.push(items[i]._id);
        }
      }
    }

    modal.show = true;
    modal.title ="Product guide";
    modal.size = 'is-medium';
    modal.body = (
      <OnProductGuide
        itemList={itemList}
        onSubmit={this.closeModal}
      />
    );
    this.setState({
      modal: modal
    }, this.openModal());
  };

  newPC9modal = async() => {
    let modal = this.state.modal;

    modal.title = 'Alta de PC9'
    modal.bodyType = 'search';
    modal.size = 'is-small'
    modal.body = (
      <AddNewPC9
        filtersUnique={this.state.filterFills}
        close={this.closeModal}
        updateTable={this.fetch}
      />
    );

    this.setState({
      pincking: false,
      modal: modal
    }, this.openModal());
  }

  showOnSearch = async() => {
    await this.setState({
      pincking: true
    });

    let modal = this.state.modal;
    let itemsList = [];
    if (this.state.checkList.length) {
      let arrays = chunk(this.state.checkList, 40);

      for (let i in arrays) {
        let itemsQuery = await this.itemService.get({
          rf: ['_id', 'status'],
          qf: {
            _id: arrays[i],
            'status.current': [ITEM_STATUS.REQUIRED, ITEM_STATUS.URGENT]
          }
        });

        if (itemsQuery.d) {
          itemsQuery = itemsQuery.d;
        } else {
          itemsQuery = [];
        }

        for (let i in itemsQuery) {
          if (itemsQuery[i].status && itemsQuery[i].status.current === ITEM_STATUS.REQUIRED) {
            itemsList.push(itemsQuery[i]._id);
          }
        }
      }
    }

    modal.title = <div>Búsqueda en almacén de <b>{itemsList.length}</b> PC9</div>
    modal.bodyType = 'search';
    modal.size = 'is-medium'
    modal.body = (
      <OnSearch2
        checkList={itemsList}
        season={process.env.APP_DEFAULT_SEASON}
        close={this.closeModal}
        updateTable={this.fetch}
      />
    );

    this.setState({
      pincking: false,
      modal: modal
    }, this.openModal());
  }

  showOnSearchSingle(item) {
    let array = [];
    array.push(item);
    this.setState({
      checkList: array
    }, () => {
      this.showOnSearch();
    });
  }

  sendToDrop = () => {
    let modal = this.state.modal;
    modal.title = 'Mover a papelera (DROP)';
    modal.size = 'is-medium';
    modal.body = (
      <SendToDrop
        closeModal={this.closeModal}
        checkList={this.state.checkList}
        loggedInUser={this.state.loggedInUser}
        seasonId={process.env.APP_DEFAULT_SEASON}
        close={this.closeModal}
        updateTable={this.fetch}
      />
    )

    this.setState({
      modal: modal
    }, this.openModal());
  }

  searchItems = () => {
    let modal = this.state.modal;
    modal.title = 'Solicitar búsqueda';
    modal.size = 'is-medium';
    modal.body = (
      <OnSearch2
        closeModal={this.closeModal}
        checkList={this.state.checkList}
        loggedInUser={this.state.loggedInUser}
        seasonId={process.env.APP_DEFAULT_SEASON}
        close={this.closeModal}
        updateTable={this.fetch}
      />
    )

    this.setState({
      modal: modal
    }, this.openModal());
  }

  setETA = () => {
    let modal = this.state.modal;
    modal.title = 'Definir ETA';
    modal.size = 'is-medium';
    modal.body = (
      <SetETA
        closeModal={this.closeModal}
        checkList={this.state.checkList}
        loggedInUser={this.state.loggedInUser}
        seasonId={process.env.APP_DEFAULT_SEASON}
        close={this.closeModal}
        updateTable={this.fetch}
      />
    )

    this.setState({
      modal: modal
    }, this.openModal());
  }

  openAddToShooting = () => {
    let modal = this.state.modal;
    modal.title = 'Shootings';
    modal.size = 'is-medium';
    modal.body = (
      <AddToShootingV2
        closeModal={this.closeModal}
        checkList={this.state.checkList}
        loggedInUser={this.state.loggedInUser}
      />
    )

    this.setState({
      modal: modal
    }, this.openModal());

  }

  closeDetailsPC9 = () => {
    this.setState({
      showDetails:false
    });
  }

  updateDetailsPC9 = (item) => {
    this.setState({
      clickedItem: item
    });
  }

  openPackage = (pkg) => {
    this.closeModal()
    this.setState({
      loading:true,
      pkg:pkg,
      pkg_active:true,
    }, () => this.fetchPKG());
  }

  exitPackage = (e) => {
    e.preventDefault()
    this.setState({
      loading:true,
      pkg:null,
      pkg_active:false
    }, ()=>this.fetch())
  };

  openQuotations = () => {
    let modal = this.state.modal
    modal.title = 'Mis cotizaciones'
    modal.bodyType = 'search';
    modal.size = 'is-large'
    modal.body = (
      <Quotation
        closeModal={this.closeModal}
        checkList={this.state.checkList}
        loggedInUser={this.state.loggedInUser}
        season={process.env.APP_DEFAULT_SEASON}
      />
    );

    this.setState({
      pincking: false,
      modal: modal
    }, this.openModal());
  };

  generateLink = () => {
    let modal = this.state.modal;
    modal.title =  'Generar link de descarga';

    modal.body = (
      <GenerateChannelLink
        quotation={this.state.pkg}
        loggedInUser={this.props.loggedInUser}
        close={this.closeModal}
        seasonId={process.env.APP_DEFAULT_SEASON}
        type={'package'}
      />
    );
    modal.show = true;
    this.setState({
      modal: modal
    });
  };

  downloadExcel = async () => {
    this.packageService.get({
      rf: ['items_stack'],
      qf: {
        _id:this.state.pkg._id
      }
    })
    .then(resp=>{
      let arrIds=[]
      for (let i= 0; i < resp.d[0].items_stack.length; i++) {
      arrIds.push(resp.d[0].items_stack[i].pc9)
      }

      this.itemService.downloadXLSConfigFull(arrIds, this.state.pkg.name)
      .then(respXLS=>{
       window.location.href = respXLS.data.d[0].url_csv
      })
      .catch(err=>{
        console.log(err)
      })
    })
  };

  // deleteFromPackage = async()=>{
  //   console.log(this.state.pkg)
  //   console.log(this.state.checkList)

  //   this.packageService.get({
  //     rf: ['items_stack', 'items_count'],
  //     qf: {
  //       _id:this.state.pkg._id
  //     }
  //   })
  //   .then(response=>{
  //     let actualItems_stack = response.d[0].items_stack.map(item => item._id)
  //     let itemsToDelete = this.state.checkList
  //     let newItems_stack = actualItems_stack.filter(item => !itemsToDelete.includes(item))
  //     console.log(newItems_stack)

  //     this.packageService.put(
        
  //     {co:{
  //       items_stack:newItems_stack,
  //       items_count:newItems_stack.length
  //     }}, this.state.pkg._id)
  //     .then(response=>{
  //       console.log(response)
  //       this.fetchPKG()
  //     })


  //   })
  // }


  addToPackage = () => {
    let modal = this.state.modal;
    modal.title = 'Agregar a paquete';
    modal.size = 'is-medium';
    modal.body = (
      <AddToPackage
        checkList={this.state.checkList}
        close={this.closeModal}
        loggedInUser={this.props.loggedInUser}
      />
    );
    this.setState({
      modal
    }, this.openModal());
  }


  render() {
    return (
      <div>
        <ModalCard
          title={this.state.modal.title}
          footer={this.state.modal.footer}
          show={this.state.modal.show}
          body={this.state.modal.body}
          closeModal={this.closeModal}
          size={this.state.modal.size}
        />
        {this.state.showDetails ?
        <DetailsPC9
          photoMode={false}
          clickedItem={this.state.clickedItem}
          loggedInUser={this.props.loggedInUser}
          close={this.closeDetailsPC9}
          show={this.state.showDetails}
          renderRows={this.state.renderRows}
          updateAll={this.updateDetailsPC9}
          toggleGeneralLoading={(loading) => this.setState({pageLoading: loading})}
          type={'eComm'}
        />
        : null }

        <div>
          <ItemStatusInfo data={this.state.data} />
          {this.state.pkg_active ?
          <Box style={{backgroundColor:'rgb(255,221,101)', marginTop:'0.5rem', marginBottom:'1rem', height:'40px', padding:'0rem', fontSize:'15px',display:'flex', alignItems:'center', justifyContent:'space-around'}}>
            <div>
            {this.state.loading ?
            <FontAwesomeIcon spin={true} icon={faSpinner} color={'info'}/>
            :
            <FontAwesomeIcon  icon={faBoxOpen} color={'info'}/>
            }&nbsp;
            <strong>{this.state.pkg.items_count}</strong> PC9&nbsp;
            </div>
            <div>
            <strong>{this.state.pkg.name}</strong>&nbsp;
            <a onClick={(e) => this.exitPackage(e)}><FontAwesomeIcon  icon={faWindowClose} color={'info'}/></a>
            </div>

            <div>


            <a onClick={(e)=> this.generateLink(e)}><FontAwesomeIcon  icon={faLink} color={'info'}/> &nbsp;Generar link</a>&nbsp;&nbsp;&nbsp;
            <a onClick={(e)=> this.downloadExcel(e)}><FontAwesomeIcon  icon={faFileExcel} color={'info'}/> &nbsp;Descargar XLS</a>&nbsp;&nbsp;&nbsp;
            {/* {this.state.checkList.length > 0 ? 
             <a onClick={(e)=> this.deleteFromPackage(e)}><FontAwesomeIcon  icon={faTrash} color={'danger'}/> &nbsp;Sacar de paquete</a>
            : null} */}
            </div>
          </Box>:null}
          <Datatable
            {...this.state.table}
            data={this.state.data}
            filterFills={this.state.filterFills}
            loading={this.state.loading}
            onChange={this.onChangeDatatable}
            phSearchBar={'PC-9 | SKU'}
          />
        </div>
      </div>
    );
  }
};

export default Items;

// Services/ItemsService.js

import axios from 'axios';
import qs from 'qs';

class ItemsService {
  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/item`,
      withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        })+'&tp='+ new Date().getTime();
      },
    });
    this.service = service;
  }


  get(query) {
    return this.service.get('/', { params: query })
      .then(response => response.data)
      .catch((response) => {
        console.log(response);
      });
  }

  getItemById = (id) => {
    return this.service.get('/' + id)
      .then(response => response.data.d[0])
      .catch((error) => {
        console.log(error);
        return error.response.data;
      });
  }

  put = (id = null, data) => {
    return this.service.put('/' + id ? id : '', data)
      .then(response => response.data)
      .catch(error => {
        console.log(error);
        return error.response.data;
      });
  }

  multiPut = (data) => {
    return this.service.put('/', data)
    .then(response => response.data)
    .catch((error) => {
      return error.response.data;
    })
  }

  uploadCSV = (files) => {
    let data = new FormData();
    for (let i in files) {
      data.append('files', files[i]);
    }

    return this.service.post('/upload', data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(response => response.data)
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else {
        return error;
      }
    });
  }

  createNewPC9 = (data) =>{
    return this.service.post('/newpc9', data)
    .then(response => response.data)
    .catch(error => {
      console.log(error);
      return error.response.data;
    });
  }

  deleteImages = (itemId, data) => {
    return this.service.delete('/' + itemId + '/images', {data: data})
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  }

  deleteAllImages = (id) =>{
    return this.service.post('/deleteAllImages', {
      _id:id
    })
    .then(respose => respose.data)
    .catch(error => {
      console.log(error)
    })
  }

  uploadImages = async (files, channel, item, func) => {
    let data = new FormData();
    data.append('_id', item._id)
    data.append('channel', channel.channel)
    data.append('id_channel', channel.id_channel)
    data.append('sku', channel.sku)
    
    for (let i in files) { 
      data.append('files', files[i]);
    }
    
    return this.service.post('/uploadImages', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          if (func !== undefined){
            func.onUploadProgress(progressEvent.loaded / progressEvent.total)
          }
        }
      })
      .then(response => response.data) 
      .catch(error => (error));
  }

  approveCopy = (idsArray) => this.service.put("/", {
      ol: Array.isArray(idsArray) ? idsArray : [idsArray],
      co: [{
        "status.mkt": "approved"
      }]
    })
    .then((response) => response.data)
    .catch((error) => error.response.data);
    

  aproveItem = (item) => {
    let channelsToGenerate = item.channels.filter(ch => { return ch.sku != null && ch.images.length === 0})

    return this.service.post('/aproveItem',{
      itemId:item._id,
      channelsToGenerate:channelsToGenerate
    })
    .then(response => response.data)
    .catch(error => {
      return error.response.data;
    });

  }
  adaptations = (channels, item, channelBase = "master") => {
    console.log(channels)
    if (!Array.isArray(channels)) {
      channels = [channels];
    }

    return this.service.post('/' + item._id + '/adaptations', {
      channels: channels,
      channelBase: channelBase
    })
      .then(response => response.data)
      .catch(error => {
        return error.response.data;
      });
  }

  addNewChannel = (item, newChannel, newSku) => {
    return this.service.post('/addNewChannel', {
        itemId: item._id,
        channel: newChannel.name,
        channelId: newChannel._id,
        sku: newSku
      })
      .then(response => response.data)
      .catch(error =>{
        console.log(error)
      });
  }

  setChannelToNull = (item, channel)=>{

    return this.service.post('/setChannelToNull',{
        itemId: item._id,
        channel: channel.channel
      })
      .then(resp => resp.data)
      .catch(err => {
        console.log(err)
      });
  }

  swatch = (id, params) => {
    return this.service.post('/' + id + '/swatch', {
        ol: [id],
        co: [params]
      })
      .then(response => response.data)
      .catch();
  }

  deleteSwatch = (id) => {
    return this.service.put('/' + id + '/swatch', {
      co: [{
        "$action": "delete"
      }]
    })
      .then(response => response.data)
      .catch();
  }

  generateCuts = (items, channel) => {
    return this.service.post('/cutb', {
        co: [{
          "item": (Array.isArray(items) ? items : [items]),
          "channel": (Array.isArray(channel) ? channel : [channel])
        }]
      })
      .then(response => response.data)
      .catch();
  }

  fieldsUnique = (fields, query) => {
    return this.service.get("/fields/unique", {
        params: {
          rf: fields,
          qf: query
        }
      })
        .then(response => response.data)
        .catch();
  }

  pinCuts = (id) => {
    return this.service.post(`/${id}/pincuts`, {})
      .then(response => response.data)
      .catch();
  }

  downloadXLS = (items, channel, name) =>{
    return this.service.post('/csv', {
      "item": items,
      "channel": [channel],
      "opt":{
        "file_name":name
      }
    }) 
  }



  downloadXLSConfigFull = (pc9s, name) =>{
    return this.service.post('/xlsx',{
      "filter": {
        "type": "items",
        "pool": pc9s
      },
      "config": {
        "file_name": name,
        "headers": [
          'pc9',
          'brand',
          'gender',
          'clothes_collection',
          'line',
          'season',
          'month',
          'category',
          'subcategory',
          'category_tree',
          'garment_type',
          'name',
          'sears_name',
          'simple_description',
          'marketing_description',
          'web_description',
          'official_color',
          'simple_color',
          'composition',
          'primary_material',
          'stretch',
          'fit_and_sizing',
          'fit_alone',
          'fit_reference',
          'fit_type_neck_waist_hood',
          'arm_leg_lenght',
          'inseam_lenght',
          'bullets',
          'close_garment',
          'front_design',
          'pockets',
          'pockets_quantity',
          'packaging',
          'packaging_quantity',
          'technology',
          'wash_instructions',
          'model_height',
          'sample_size',
          'model_size',
          'fitting_model_sample',
          'status',
          'copy',
          'swatch',
          'cut',
          'last_shooting',
          'eta_photos',
        ]
      }
    }) 
  }

  downloadXLSConfig = (pc9s, name) =>{
    return this.service.post('/xlsx',{
      "filter": {
        "type": "items",
        "pool": pc9s
      },
      "config": {
        "file_name": name,
        "headers": [
          "pc9",
          "brand",
          "gender",
          "category",
          "month",
          "season",
          'status',
          'copy',
          'swatch',
          'cut',
          'last_shooting',
          'eta_photos',
        ]
      }
    }) 
  }
}


export default ItemsService;

import React, { Component } from 'react';
import PackageService from '../../../Services/PackageService'

import {
  Box,
  Button,
  Form,
  Tag
} from 'react-bulma-components/full';

import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';

class AddToPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkList: [],
      loading: false,
      packages: [],
      newPkg: false,
      package: ''
    }
    this.packageService = new PackageService();

  }

  componentWillMount() {


    this.packageService.get({
      rf: ['name'],
        qf: {
          owner:this.props.loggedInUser._id,
          status:['active']
        }
    }).then((response) => {
      this.setState({
        packages: response.d
      });
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let pkgName = this.state.packages.filter(pkg => pkg._id === this.state.package)[0].name;

    let data = {
      ol: [this.state.package],
      co: [
        {
          items_stack: this.props.checkList
        }
      ]
    };

    console.log(data)

    this.packageService.put(data, this.state.package)
      .then(resp => {
        console.log(resp)
        let pkg = resp.d[0].ref
        toast.success("Los PC9's han sido agregados al paquete " + pkg.name);
        this.props.close();
        if(this.props.callSharedWith){
          this.props.callSharedWith(pkg)
        } else{
            this.props.close();
        }
      })
      .catch(error => {
        toast.success("Los PC9's han sido agregados al paquete ");
        this.props.close();
        console.log(error)

      })
    
  }

  onChange = (e) => {
    this.setState({
      package: e.target.value
    });
  }

  createNewPkg = () => {
    this.setState({
      newPkg: true,
      package: ''
    })


  }

  createAddToNewPkg = () => {
    this.packageService.post({
      co: [{
        owner: this.props.loggedInUser._id,
        _items: this.props.checkList,
        name: this.state.package
      }]
    }).then(resp => {
        let pkg = resp.d[0].ref
        toast.success("Los PC9's han sido agregados al nuevo paquete " + pkg.name);
        if(this.props.callSharedWith){
          this.props.callSharedWith(pkg)
        } else {
        this.props.close();
        }
        
        //this.props.close();
      })
      .catch(error => {
        console.log(error)
        toast.error("Sucedió un error, inténtalo de nuevo");
      })
  }

  render() {
    return (
      <Box className="is-marginless">
        <div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">PC9's</label>
            </div>
            <div className="field-body">
              <div className="field">
                <Tag.Group>
                  <Tag color="info">{this.props.checkList.length}</Tag>
                  <Tag>Seleccionados</Tag>
                </Tag.Group>
              </div>
            </div>
          </div>

          {!this.state.newPkg ?
            <div className="field is-horizontal" >
              <div className="field-label is-normal">
                <label className="label">Paquete</label>
              </div>
              <div className="field-body">
                <div className="field" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Form.Select onChange={this.onChange} name="package" value={this.state.package}>
                    <option value="" disabled>Selecciona un paquete</option>

                    {this.state.packages.map((element, index) => {
                      return (
                        <option value={element._id} key={index}>{element.name}</option>
                      );
                    })}
                  </Form.Select>&nbsp;&nbsp;
                  <a onClick={(e) => this.createNewPkg()}>O crea uno nuevo</a>
                </div>

              </div>

            </div>
            :
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Paquete</label>
              </div>
              <div className="field-body">
                <div className="field" >
                  <Form.Input
                    autocomplete="off"
                    placeholder='Ponle un nombre'
                    value={this.state.package}
                    name="name"
                    onChange={this.onChange}
                    required
                    style={{ width: '300px' }}
                  />
                </div>
              </div>
            </div>
          }

          <div className="field is-grouped is-grouped-right">
            {!this.state.newPkg ?
              <Button color="warning" onClick={this.handleSubmit} disabled={this.props.checkList.length < 1}>Agregar</Button>
              :
              <Button color="warning" onClick={this.createAddToNewPkg} disabled={this.props.checkList.length < 1 || this.state.package === ''}>Crear y agregar PC9's</Button>
            }
          </div>
        </div>
      </Box>
    );
  }
}

AddToPackage.propTypes = {
};

AddToPackage.defaultProps = {
};

export default AddToPackage;

import React, { useEffect, useState } from 'react';
import PkgService from '../../../Services/PackageService';
import ItemsService from '../../../Services/ItemsService';
import ChannelsService from '../../../Services/ChannelsService';

import {
  Box,
  Button,
  Columns,
  Form,
  Heading,
  Level,
  Tabs,
  Tag
} from "react-bulma-components/full";

import { faBox, faTrash, faLink, faPlus, faBoxOpen, faDownload, faShare, faFileExcel, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import Datatable from '../../General/Datatable/Datatable';
import Loader from '../../General/Loader/Loader';
import ModalCard from '../../General/Modal/ModalCard';
import GenerateChannelLink from './GenerateChannelLink2';
import PackageShareWith from './PackageShareWith';

import './package.scss';
import { sortBy } from '../../General/Datatable/utils';

const pkgService = new PkgService();
const itemsService = new ItemsService();
const channelService = new ChannelsService();

const Packages = (props) => {

  const query = {
    own: {
      rf: ['name', 'items_count', 'created_at', 'id_season', 'updated_at', 'owner', 'share'],
      qf: {
        owner: props.loggedInUser._id,
        status: 'active',
      }
    },
    shared: {
      rf: ['name', 'items_count', 'created_at', 'id_season', 'updated_at', 'owner','share'],
      qf: {
        owner: props.loggedInUser._id,
        status: 'active',
        shared: 'true'
      }
    }
  };

  const [activeTab, setActiveTab] = useState('own');
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const [checkList, setCheckList] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState({
    show: false,
    size: ''
  });
  const [pkgName, setPkgName] = useState('');
  const [newPackage, setNewPackage] = useState(false)
  const [queryString, setQueryString] = useState(query[activeTab]);


  const table = {
    checkCounter: false,
    searcheable: false,
    loadingLegend: <Loader />,
    filtersConfig: {
      ownerId: {
        label: 'Creado por',
      }
    },
    searchFields: ['name'],
    actions: [
      {
        icon: faTrash,
        disabled: false,
        label: 'Eliminar paquetes',
        className: 'is-danger',
        visible: () => checkList.length,
        onClick: () => confirmDeletePkg(checkList)
      },
      {
        title: 'Nuevo paquete vacío',
        label: 'Crear paquete',
        icon: faPlus,
        className: (newPackage ? 'default' : 'is-info'),
        onClick: () => setNewPackage(!newPackage)
      }
    ],
    columns: [{
      field: '_id',
      type: 'checklist',
    }, {
      color: 'warning',
      field: 'pkg_id',
      label: 'Entrar',
      type: 'button',
      icon: faBoxOpen,
      onClick: (pkg) => selectPackage(pkg),
    }, {
      label: 'Nombre paquete',
      field: 'name',
      sortable: true
    }, {
      label: "PC9's",
      field: 'items_count',
      sortable: true
    }, {
      label: 'Creado',
      field: 'created_at',
      format: ['MOMENT::DD/MM/YY'],
      sortable: true
    },
    {
      label: 'Por',
      field: 'owner.email',
      sortable: true
    },
    // {
    //   label: 'Compartido con',
    //   field: 'share.length',
    //   sortable: true
    // },
    {
      label: 'Acciones',
      type: 'actions',
      actions: [
        {
          title: "Generar link",
          icon: faLink,
          color: 'success',
          onClick: (pkg) => generateLink(pkg)
        }, {
          title: "Descargar Excel",
          icon: faFileExcel,
          color: 'warning',
          onClick: (pkg) => downloadExcel(pkg)
        },
        {
          title: "Compartir paquete",
          icon: faShare,
          color: 'info',
          onClick: (pkg) => shareWith(pkg),
          visible: () => (activeTab === 'own')
        }
      ]
    }

    ]
  };

  const downloadExcel = async (pkg) => {
    pkgService.get({
      rf: ['items_stack'],
      qf: {
        _id: pkg._id
      }
    })
      .then(resp => {
        let arrIds = []
        for (let i = 0; i < resp.d[0].items_stack.length; i++) {
          arrIds.push(resp.d[0].items_stack[i].pc9)
        }

        itemsService.downloadXLSConfigFull(arrIds, pkg.name)
          .then(respXLS => {
            window.location.href = respXLS.data.d[0].url_csv
          })
          .catch(err => {
            console.log(err)
          })
      })
  }


  const createNewPackage = () => {
    pkgService.post({
      co: [{
        owner: props.loggedInUser._id,
        _items: checkBoxChecked ? props.checkList : [],
        name: pkgName
      }]
    })
      .then(resp => {
        fetch();
        setNewPackage(false)
        setCheckBoxChecked(false);
        setPkgName('');
        toast.success('El paquete ha sido creado correctamente')
      });

  };



  const deletePackage = (checkList) => {

    for (let i = 0; i < checkList.length; i++) {
      pkgService.get({
        rf: ['owner'],
        qf: {
          _id: checkList[i],
          status: 'active',
        }
      })
        .then(response => {
          let pkgToDelete = response.d[0]
          if (pkgToDelete.owner === props.loggedInUser._id) {
            pkgService.put({
              ol: checkList,
              co: [{
                status: 'eliminated'
              }]
            })
            .then(resp=>{
              fetch();
            })
          } else {
           pkgService.removeShare(pkgToDelete._id, [props.loggedInUser._id])
           .then(resp=>{
            fetch();
           })

          }
        })
        .catch(error => {
          console.log(error)
        })

    }

    closeModal();
    toast.success('Se han eliminado los paquetes')
    setCheckList([]);
  };


  const selectPackage = (pkg) => {
    if (pkg.items_count > 0) {
      props.openPackage(pkg);
    } else if (pkg.items_count === 0) {
      toast.warning("El paquete seleccionado no tiene PC9's asignados, selecciona otro");
    }
  };


  const fetch = async () => {
    await setData([]);
    pkgService.get(queryString).then((response) => {
      let sorted = sortBy(response.d, {
        field: 'updated_at',
        order: 'desc'
      });
      setData(sorted);
    });
  };


  const generateLink = (pkg) => {
    let newModal = { ...{}, ...modal };
    newModal.title = 'Generar link de descarga';
    newModal.show = true;

    newModal.body = (
      <GenerateChannelLink
        quotation={pkg}
        loggedInUser={props.loggedInUser}
        close={closeModal}
        type={'package'}
      />
    );

    setModal({ ...{}, ...newModal });
  };


  const closeModal = () => {
    setModal({ ...{}, ...{ show: false } });
  };


  const confirmDeletePkg = async (pkg) => {
    let newModal = { ...{}, ...modal };
    newModal.title = "Eliminar paquete";
    newModal.size = 'is-small';
    newModal.show = true;
    newModal.body = (
      <div>
        <p className="has-text-centered"><b>
          {Array.isArray(pkg) ? "Los paquetes seleccionados serán eliminados" : "El paquete será eliminado"}</b></p>
        <br />
        <Columns centered={true}>
          <Columns.Column>
            <Button
              color="info"
              fullwidth={true}
              onClick={() => closeModal()}
            >
              No, cancelar
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              color="warning"
              fullwidth={true}
              onClick={() => {
                if (Array.isArray(pkg)) deletePackage(pkg)
                else deletePackage(pkg._id)
              }}
            >
              Sí, eliminar
            </Button>
          </Columns.Column>
        </Columns>

      </div>
    );

    setModal({ ...{}, ...newModal });
  };

  const onChangeDatatable = (state) => {
    let { checkList: _checkList } = state;

    if (checkList !== _checkList) {
      setCheckList(_checkList);
    }
  }

  const shareWith = (pkg) => {
    let newModal = { ...{}, ...modal };
    newModal.title = "Compartir " + pkg.name;
    newModal.show = true;
    newModal.body = (<PackageShareWith
      ownerId={props.loggedInUser._id}
      packageId={pkg._id}
    />);

    setModal({ ...{}, ...newModal });
  };

  useEffect(() => {
    setQueryString(query[activeTab])
  }, [activeTab]);

  useEffect(() => {
    if (queryString && Object.keys(queryString).keys) {
      setLoading(true);
    }
  }, [queryString])

  useEffect(() => {
    if (loading) {
      fetch();
    }
  }, [loading]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [data]);

  return (
    <div className="modal-package">
      <ModalCard
        {...modal}
        closeModal={closeModal}
      />

      {
        newPackage ?

          <Box className="header">
            <Level renderAs="nav">
              <Level.Side align="left">
                <Level.Item>
                  <Heading size={5} subtitle>
                    <Tag.Group gapless>
                      <Tag color="info">{props.checkList.length}</Tag>
                      <Tag>PC9's</Tag>
                    </Tag.Group>
                  </Heading>
                </Level.Item>
              </Level.Side>

              <Level.Side align="right">
                <Level.Item><FontAwesomeIcon icon={faBox} /> </Level.Item>
                <Level.Item>
                  <Form.Field kind="group">
                    <Form.Control>
                      <Form.Input
                        autoComplete="off"
                        placeholder="Ponle nombre"
                        autofocus={true}
                        value={pkgName}
                        onChange={(e) => setPkgName(e.target.value)}
                      />
                    </Form.Control>
                  </Form.Field>
                </Level.Item>

                {props.checkList.length ?
                  <Level.Item>
                    <Form.Checkbox
                      checked={checkBoxChecked}
                      onChange={(e) => setCheckBoxChecked(e.target.checked)}
                    />
                    &nbsp;
                    Incluir <strong> &nbsp;{props.checkList.length} &nbsp; </strong> PC9's seleccionados
                  </Level.Item>
                  :
                  null
                }

                <Level.Item>
                  <Button
                    disabled={pkgName.length === 0 ? true : false}
                    renderAs="a"
                    color="warning"
                    onClick={createNewPackage}
                  >
                    Nuevo paquete
                  </Button>
                </Level.Item>
              </Level.Side>
            </Level>
          </Box>
          :
          null
      }

      <Tabs
        type="boxed"
        fullwidth={true}
      >
        <Tabs.Tab
          active={activeTab === 'own'}
          onClick={() => setActiveTab('own')}
          disabled={loading}
        >
          Mis paquetes
        </Tabs.Tab>
        <Tabs.Tab
          active={activeTab === 'shared'}
          onClick={() => setActiveTab('shared')}
          disabled={loading}
        >
          Compartidos conmigo
        </Tabs.Tab>
      </Tabs>

      <Datatable
        {...table}
        data={data}
        emptyLegend={'Aún no creas ningún folder'}
        loading={loading}
        onChange={onChangeDatatable}
      />

    </div>
  );
}

export default Packages;

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from "react-router-dom";

import { Container } from "react-bulma-components/full";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Items from "../../Items/Items";
import Sidebar from "../Sidebar/Sidebar";
import Account from "../../Account/Account";
import LookingFor from "../../LookingFor/Looking-for";
import Shootings from "../../Shootings/Shooting";
import RecycleBin from "../../RecycleBin/RecycleBin"
import AuthService from "../../../Services/AuthService";
import Channels from "../../Channels/Channels";
import ActivityLog from "../ActivityLog/ActivityLog";
import Quotations from "../../Quotations/Quotations";
import SupportEmail from "../../SupportEmail";
import { Buscador, CurbsideData } from "../Curbside";
import Dashboard from "../../Dashboard";

import "./style.scss";

const service = new AuthService();

const Layout = ({ loggedInUser, redirect, ...props }) => {

  const [_loggedInUser, setLoggedInUser] = useState(loggedInUser);
  const [menu, setMenu] = useState({ visible: false });
  const [isFluid, setIsFuild] = useState(false);

  const logout = () => {
    service.logout().then(() => {
      setLoggedInUser(false);
      props.setUser(false);
      localStorage.clear();
    });
  };

  return (
    <Router>
      <Header
        setUser={props.setUser}
        toggleMenu={() => setMenu((v) => ({
          visible: !v.visible
        }))}
        loggedInUser={loggedInUser}
        onChangeFluid={setIsFuild}
      />
      <SupportEmail
        loggedInUser={loggedInUser}
        title="Contacto Service desk" />
      <div className="content-app columns is-fullheight is-top-marginless">
        {menu.visible ? (
          <Sidebar
            logout={logout}
            loggedInUser={loggedInUser}
            version={props.version}
          />
        ) : null}

        <Container className={`column${!menu ? " is-10" : ""}${isFluid ? " is-fluid" : ""}`}>
          <Switch>
            <Route
              path="/account"
              exact
              render={(props) => (
                <Account
                  {...props}
                  loggedInUser={loggedInUser}
                />
              )}
            />

            <Route
              path="/curbside"
              exact
              render={(props) => (
                <Buscador
                  {...props}
                  loggedInUser={loggedInUser}
                />
              )}
            />
            <Route
              path="/curbside/:pc9"
              render={(props) => (
                <CurbsideData
                  {...props}
                  loggedInUser={loggedInUser}
                />
              )}
            />

            {loggedInUser && loggedInUser.code === "curbside" ?
              <Redirect to='/curbside' />
              : null}


            <Route
              path="/master"
              exact
              render={(props) => (
                <Items
                  {...props}
                  loggedInUser={loggedInUser}
                />
              )}
            />

            <Route
              path="/looking-for/ecomm"
              exact
              render={(props) => (
                <LookingFor
                  {...props}
                  loggedInUser={loggedInUser}
                  type={"eComm"}
                />
              )}
            />
            <Route
              path="/looking-for/pguide"
              exact
              render={(props) => (
                <LookingFor
                  {...props}
                  loggedInUser={loggedInUser}
                  type={"pGuide"}
                />
              )}
            />
            <Route
              path="/shootings"
              exact
              render={(props) => (
                <Shootings
                  {...props}
                  loggedInUser={loggedInUser}
                />
              )}
            />

            <Route
              path="/recyclebin"
              exact
              render={(props) => (
                <RecycleBin
                  {...props}
                  loggedInUser={loggedInUser}
                />
              )}
            />
            <Route
              path="/activity"
              exact
              render={(props) => (
                <ActivityLog
                  {...props}
                  loggedInUser={loggedInUser}
                />
              )}
            />
            <Route
              path="/config/channels"
              exact
              render={(props) => (
                <Channels
                  {...props}
                  loggedInUser={loggedInUser}
                />
              )}
            />

            <Route
              path="/quotations"
              exact
              render={(props) => (
                <Quotations
                  {...props}
                  loggedInUser={loggedInUser}
                />
              )}
            />

            <Route
              path="/dashboard"
              exact
              render={(props) => (
                <Dashboard
                  {...props}
                  loggedInUser={loggedInUser}
                />
              )}
            />

            <Redirect
              path="/"
              to={redirect ? redirect : "/master"}
            />
          </Switch>
        </Container>
      </div>
      <Footer />

    </Router>
  );
}

export default Layout;

import React, { useEffect, useState } from 'react';
import {
  useHistory,
  useLocation,
  withRouter
} from "react-router-dom";
import { Navbar } from "react-bulma-components/full";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import ModalCard from '../../../components/General/Modal/ModalCard'; // <-- Adjust import path
import './style.scss';

const Header = ({ loggedInUser, onChangeFluid, ...props }) => {
  const history = useHistory();
  const location = useLocation();

  // State for our modal
  const [showModal, setShowModal] = useState(false);


  // State for which video is currently active
  const [activeVideo, setActiveVideo] = useState('video1');

  // Object mapping each "tag" to a distinct video URL
  const videos = {
    video1: 'https://videostmp.s3.us-west-1.amazonaws.com/intro.mp4', 
    video2: 'https://videostmp.s3.us-west-1.amazonaws.com/filtrar_pc9.mp4',
    video3: 'https://videostmp.s3.us-west-1.amazonaws.com/crear_paquete.mp4',
    video4: 'https://videostmp.s3.us-west-1.amazonaws.com/generar_adaptaciones.mp4',
    video5: 'https://videostmp.s3.us-west-1.amazonaws.com/descargar_adaptaciones.mp4',
    video6: 'https://videostmp.s3.us-west-1.amazonaws.com/necesitas_renombrado.mp4',
  };

  // Handlers for opening/closing the modal
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  useEffect(() => {
    if (["/", "/dashboard"].indexOf(location.pathname) !== -1) {
      onChangeFluid(true);
    } else {
      onChangeFluid(false);
    }
  }, [location.pathname, onChangeFluid]);

  return (
    <>
      <Navbar
        id="navbar"
        fixed="top"
        className="push-top has-shadow no-print"
      >
        <Navbar.Brand>
          <Navbar.Burger onClick={props.toggleMenu} />

          <Navbar.Item onClick={() => history.push("/")}>
            <img
              alt="Phototool Levi's"
              src="/images/logo_pt_levis.png"
            />&nbsp; Phototool
          </Navbar.Item>
        </Navbar.Brand>

        <Navbar.Menu>
          <Navbar.Container position="end" className="is-mobile">
            {/* ---------------------------
                SECTION WHERE WE TRIGGER THE MODAL
             --------------------------- */}
            <Navbar.Item renderAs="div">
              <div style={{ fontSize: '12px' }}>
                <strong>¿Necesitas una capacitación?</strong>&nbsp;&nbsp;&nbsp;
                {/* Instead of a regular link, we call openModal */}
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer', color: '#3273dc' }}
                  onClick={openModal}
                >
                  Mira este video
                </span>
              </div>
            </Navbar.Item>

            {/* ---------------------------
                USER INFO
             --------------------------- */}
            <Navbar.Item renderAs="div">
              <FontAwesomeIcon style={{ width: '30px', height: '30px' }} icon={faUser} />&nbsp;
              <div style={{ fontSize: '12px' }}>
                <strong>{loggedInUser.account.first_name + ' ' + loggedInUser.account.last_name}</strong>
                <p>{loggedInUser.account.job_title}</p>
              </div>
            </Navbar.Item>

            <Navbar.Item>&nbsp;</Navbar.Item>
          </Navbar.Container>
        </Navbar.Menu>
      </Navbar>

      {/* ---------------------------
          MODAL CARD
       --------------------------- */}
      <ModalCard
        title="Videos tutoriales"
        footer={null}
        show={showModal}
        closeModal={closeModal}
        size="is-medium"
        // The `body` prop can be anything; for example an <iframe> for Loom:
        body={
          <div style={{ width: '100%' }}>
            {/*
              A simple Bulma "tabs" component:
              https://bulma.io/documentation/components/tabs/
            */}
            <div className="tabs is-toggle is-small">
              <ul>
                {/* Tag 1 */}
                <li className={activeVideo === 'video1' ? 'is-active' : ''}>
                  <a onClick={() => setActiveVideo('video1')}>
                    Intro
                  </a>
                </li>

                {/* Tag 2 */}
                <li className={activeVideo === 'video2' ? 'is-active' : ''}>
                  <a onClick={() => setActiveVideo('video2')}>
                    Filtrar PC9's
                  </a>
                </li>

                {/* Tag 3 (optional) */}
                <li className={activeVideo === 'video3' ? 'is-active' : ''}>
                  <a onClick={() => setActiveVideo('video3')}>
                    Crear paquete
                  </a>
                </li>

                          {/* Tag 3 (optional) */}
                          <li className={activeVideo === 'video4' ? 'is-active' : ''}>
                  <a onClick={() => setActiveVideo('video4')}>
                    Generar adaptaciones
                  </a>
                </li>

                          {/* Tag 3 (optional) */}
                          <li className={activeVideo === 'video5' ? 'is-active' : ''}>
                  <a onClick={() => setActiveVideo('video5')}>
                    Descargar adaptaciones
                  </a>
                </li>

                 {/* Tag 3 (optional) */}
                 <li className={activeVideo === 'video6' ? 'is-active' : ''}>
                  <a onClick={() => setActiveVideo('video6')}>
                    ¿Necesitas renombrado especial?
                  </a>
                </li>
              </ul>
            </div>

            {/* The video iframe. Updates based on `activeVideo`. */}
            <div style={{ width: '100%', height: '500px' }}>
              <iframe
                src={videos[activeVideo]}
                title="Video capacitación"
                frameBorder="0"
                allowFullScreen
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default withRouter(Header);

import React, { useState } from 'react';
import './style.scss'
import AuthService from '../../Services/AuthService';
import {
  Link
} from "react-router-dom";

import {
  Button,
  Container,
  Columns,
  Form,
  Heading
} from "react-bulma-components/full";

const authService = new AuthService();
const Forgot = (props) => {

  const [email, setEmail] = useState();
  const [sending, setSending] = useState();
  const [sent, setSent] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    setSending(true);
    authService.forgot(email)
      .then( response => {
        setSent(true);
      })
      .catch(() => {
        setSending(false);
      });
  }

  return (
    <Container className="has-text-centered">
    <Columns>
      <Columns.Column size={4} offset={4}>
        
        <div className="box">
          <div className="avatar">
            <figure className="avatar">
              <img style={{width:'150px'}} src="https://levis.phototool.online/images/logo_pt_levis.png" alt="Phototool" className="avatar-circle"/>
            </figure>
          </div>
          <Heading size={4} className="title has-text-grey">Recuperar contraseña</Heading>

          {sent ? 
            <p>Te enviamos un correo.</p>
          :
            <form onSubmit={handleSubmit}>
              <Form.Field>
                <Form.Control>
                  <Form.Input
                    autoComplete="off"
                    value={email}
                    name="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  >
                  </Form.Input>
                </Form.Control>
              </Form.Field>

              <Button
                color="info"
                className="is-block is-fullwidth"
                disabled={sending}
              >
                Recuperar
              </Button>
            </form>
          }
        </div>

        <p className="has-text-grey">
          <Link to="/login">Iniciar sesión</Link>
        </p>
        <br />
        <p>Phototool v{props.version}</p>
      </Columns.Column>
    </Columns>
  </Container>
  );
};

export default Forgot;
